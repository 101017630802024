<template>
  <div class="visible-setting html2canvas-ignore">
    <b-card class="visible-card">
      <b-list-group>
        <b-list-group-item class="visible-item" v-if="layers.length === 0">
          <span class="layer-name text-center">No active layer</span>
        </b-list-group-item>
        <b-list-group-item class="visible-item" v-for="layer in layers" :key="layer.id">
          <span style="margin-right: 5px" class="d-flex align-items-center">
            <feather-icon icon="PlusIcon" size="16" />
          </span>
          <span class="layer-name">{{ layer.name }}</span>
          <span :title="layer.visible[mapIndex] ? $t('show_layer') : $t('hide_layer')" style="margin-left: 5px; cursor: pointer" class="d-flex align-items-center" @click="$store.commit('layer/TOGGLE_VISIBLE', { layerId: layer.id, mapIndex })">
            <feather-icon :icon="layer.visible[mapIndex] ? 'EyeIcon' : 'EyeOffIcon'" size="16" />
          </span>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>

export default {
  props: ['mapIndex'],
  computed: {
    layers() {
      return this.$store.state.layer.layers
    },
  },
}
</script>

<style scoped>
.visible-setting {
  position: absolute;
  top: 12px;
  left: 10px;
  z-index: 418;
  opacity: 0.5;
  transition: opacity 0.2s ease;
  cursor: default;
}

.visible-setting:hover {
  opacity: 1;
}

.visible-setting .visible-card {
  margin-bottom: 0;
  font-size: 12px;
}

.visible-setting .card-body {
  padding: 0;
  width: 200px;
}

.visible-setting .visible-item {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  border: none;
}

.visible-setting .visible-item:active {
  background-color: #f8f8f8;
}

.dark-layout .visible-setting .visible-item:active {
  background-color: #161d31;
  color: inherit;
}

.visible-setting .visible-item .layer-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
</style>
