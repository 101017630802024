<template>
  <div class="position-relative flex-grow-1 overflow-hidden box-iframe">
    <div class="leaflet-map" ref="leafletMap" :id="`leaflet-map-${mapIndex}`">
      <component  v-if="layer.type != 'netcdf'" :is="`${layer.type}-layer`" v-for="layer in layers" :key="layer.id" :map="map" :layer="layer" :map-index="mapIndex" ref="leafletLayer"></component>
    </div>
    <!-- Tooltip Render -->
    <HoveringTooltip v-if="hovering && hoveringLayer && hoveringLayer.tooltip.visible" :map="map" :map-index="mapIndex" :moveCount="moveCount" :layer="hoveringLayer" :leafletLayer="hoveringLeafletLayer" />
    <permanent-tooltip :map="map" :moveCount="moveCount" />

    <transition v-for="(click, index) in clicking" :key="index" name="fade">
      <component :is="`border-${popupComponent(index)}-popup`" check="true" v-if="popupComponent(index) && click && click.type !== 'netcdf' && clickingLayer[index] && clickingLayer[index].popup && clickingLayer[index].popup.visible && clickingLayer[index].popup.type === 'border' && clickingLayer[index].pin" :clicking="click" :map="map" :moveCount="moveCount" :layer="clickingLayer[index]" :leafletLayer="clickingLeafletLayer && clickingLeafletLayer[index]" :map-index="mapIndex"></component>
    </transition>
    <transition v-for="(click, index) in clicking" :key="index" name="slide">
      <component :is="`borderless-${popupComponent(index)}-popup`" v-if="popupComponent(index) && click && click.type !== 'netcdf' && clickingLayer[index] && clickingLayer[index].popup && clickingLayer[index].popup.visible && clickingLayer[index].popup.type === 'borderless'" :clicking="click" :map="map" :moveCount="moveCount" :layer="clickingLayer[index]" :leafletLayer="clickingLeafletLayer && clickingLeafletLayer[index]" :map-index="mapIndex"></component>
    </transition>
  </div>
</template>

<script>
import L from 'leaflet'
// START import for distortable image
import 'leaflet-path-drag/dist/L.Path.Drag'
import 'leaflet-distortableimage/dist/vendor'
import 'leaflet-distortableimage/dist/leaflet.distortableimage'
// END import for distortable image
import PointLayer from '../tabs/map/layers/PointLayer'
import ImageLayer from '../tabs/map/layers/ImageLayer'
import TyphoonLayer from '../tabs/map/layers/TyphoonLayer'
import MultiLayer from '../tabs/map/layers/MultiLayer'
import '/static/vendor/leaflet-extra-shapes/leaflet.extra-shapes.js'
import '/static/vendor/leaflet-custom-box/custom-map-box-selection.js'
import HoveringTooltip from '../tabs/map/tooltips/HoveringTooltip.vue'
import PermanentTooltip from '../tabs/map/tooltips/PermanentTooltip.vue'
import TileSetting from '../tabs/map/controls/TileSetting.vue'
import VisibleSetting from '../tabs/map/controls/VisibleSetting.vue'
import BorderTyphoonPopup from '../tabs/map/popups/BorderTyphoonPopup.vue'
import BorderlessTyphoonPopup from '../tabs/map/popups/BorderlessTyphoonPopup.vue'
import BorderVectorPopup from '../tabs/map/popups/BorderVectorPopup.vue'
import BorderlessVectorPopup from '../tabs/map/popups/BorderlessVectorPopup.vue'
import { BToast } from 'bootstrap-vue'

const MAP_CONFIG = {
  zoomControl: false,
  preferCanvas: true,
  customBoxZoom: true,
  minZoom: 2,
  maxBounds: [
    [85, 10000],
    [-85, -10000],
  ],
  worldCopyJump: true,
}

L.Map.addInitHook(function () {
  this.getContainer()._leaflet_map = this
})

export default {
  components: { TileSetting, BToast, PointLayer, ImageLayer, TyphoonLayer, MultiLayer, VisibleSetting, BorderTyphoonPopup, BorderlessTyphoonPopup, BorderVectorPopup, BorderlessVectorPopup, HoveringTooltip, PermanentTooltip },
  data() {
    return {
      map: null,
      mapIndex: 0,
      moveCount: 0,
    }
  },
  mounted() {
    this.map = L.map(this.$refs.leafletMap, MAP_CONFIG).setView([0, 0], 2)

    this.tileLayer = L.tileLayer(this.tile.url, { ...this.tile }).addTo(this.map)

    if (this.mapBounds && this.mapBounds[this.mapIndex]) {
      this.map.fitBounds(this.mapBounds[this.mapIndex])
    }
    this.map.attributionControl.setPosition('topright')

    // listen to map div resize => invalidate
    new ResizeObserver(() => {
      this.map.invalidateSize()
    }).observe(this.$refs.leafletMap)
    const storeData = {
      data: null,
      mapIndex: this.mapIndex,
    }
    this.map.on('click', () => {
      this.$store.commit('map/SET_CLICKING', storeData)
    })
    this.map.on('move', () => {
      this.moveCount++
    })
    this.sortLayer(this.layers)
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources
    },
    layers() {
      return this.$store.state.layer.layers
    },
    mapBounds() {
      return this.$store.state.map.mapBounds
    },
    tile: {
      get() {
        return this.$store.state.map.mapTiles[this.mapIndex]
      },
      set(tile) {
        if (this.tile.url == tile.url) return
        this.$store.commit('map/SET_MAP_TILE', { tile, mapIndex: this.mapIndex })
      },
    },
    clicking() {
      return this.$store.state.map.clicking[this.mapIndex]
    },
    clickingLayer() {
      let array = []
      for (let i = 0; i < this.clicking.length; i++) {
        let layer = this.layers.find((l) => l.id === this.clicking[i].layerId)
        if (layer) {
          array.push(layer)
        }
      }
      return array
    },
    clickingLeafletLayer() {
      let leafletLayer = null
      for (let i = 0; i < this.clicking.length; i++) {
        if (this.map && this.map.eachLayer) {
          this.map.eachLayer((l) => {
            if (l.id === this.clicking[i].layerId) {
              if (!leafletLayer) leafletLayer = []
              leafletLayer.push(l)
            }
          })
        }
      }
      return leafletLayer
    },
    hovering() {
      return this.$store.state.map.hovering[this.mapIndex]
    },
    hoveringLayer() {
      return this.layers.find((l) => l.id === this.hovering.layerId)
    },
    hoveringLeafletLayer() {
      let leafletLayer = null
      this.map.eachLayer((l) => {
        if (l.id === this.hovering.layerId) leafletLayer = l
      })
      return leafletLayer
    },
  },
  watch: {
    mapBounds(mapBounds) {
      if (mapBounds && mapBounds[this.mapIndex]) {
        this.map.fitBounds(mapBounds[this.mapIndex])
      }
    },
    tile() {
      // remove and add again to update attribution
      this.tileLayer.remove()
      this.tileLayer = L.tileLayer(this.tile.url, { ...this.tile }).addTo(this.map)
    },
    clicking(clicking) {
      if (!clicking || !this.clickingLeafletLayer || clicking.source !== 'sidebar') return

      let location = this.clickingLeafletLayer.getLayers().find((l) => l.feature.properties.ID === this.clicking.clickId && l.feature.properties.UID === this.clicking.clickUid)

      if (!location) return

      this.map.panTo(location.latlng, { animate: false })
    },
  },
  methods: {
    sortLayer(layers) {
      this.$nextTick(() => {
        setTimeout(() => {
          for (let i = layers.length - 1; i >= 0; i--) {
            let leafletLayer = this.$refs.leafletLayer.find((ref) => ref.leafletLayer && ref.leafletLayer.id === layers[i].id)
            if (leafletLayer) leafletLayer.leafletLayer.bringToFront()
          }
        }, 0)
      })
    },
    popupComponent(index) {
      // Point, Multi layer have same popup => use this var help not have to write 3 popup component
      try {
        if (this.clickingLeafletLayer[index].type === 'typhoon') return 'typhoon'
        if (['point', 'multi'].includes(this.clickingLeafletLayer[index].type)) return 'vector'
      } catch {}
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.leaflet-map {
  position: relative;
  flex-grow: 1;
  height: calc(100vh - 94.5px - 42px - 1rem);
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-leave,
.fade-enter-to {
  opacity: 1;
}

.slide-enter-active,
.slide-leave-active {
  transition: max-width 0.5s linear;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  max-width: 0;
}
.slide-leave,
.slide-enter-to {
  max-width: 1000px;
}
</style>

<style>
.my-tooltip {
  border-radius: 0;
  box-shadow: none;
}
.my-tooltip.leaflet-tooltip-left:before {
  border-left-color: var(--caret-color);
}
.my-tooltip.leaflet-tooltip-right:before {
  border-right-color: var(--caret-color);
}
.my-tooltip.leaflet-tooltip-top:before {
  border-top-color: var(--caret-color);
}
.my-tooltip.leaflet-tooltip-bottom:before {
  border-bottom-color: var(--caret-color);
}
#group-unselect-toast .toast-body {
  padding: 10px;
}
#group-unselect-toast ul {
  margin: 0;
}

.embed-container .slider-container {
  margin-bottom: 5px !important;
  padding: 0 5px;
  height: 30px;
}
.embed-container .btn.btn-icon {
  padding: 0.515rem 0.515rem !important;
}
.noUi-horizontal {
  height: 5px !important;
}
.noUi-horizontal .noUi-handle {
  width: 13px !important;
  height: 13px !important;
}
.slider-container .main-container {
  height: 28px !important;
  top: 5px !important;
}
.embed-container .slider-container .control-button {
  border-radius: 0;
  margin-right: 5px !important;
}
.embed-container .slider-container .repeat-button,
.embed-container .slider-container .lazy-button,
.embed-container .slider-container .speed-slider,
.embed-container .slider-container .speed-display {
  margin-left: 5px !important;
}

.vue-daterange-picker .reportrange-text,
.slider-container .fps-display,
.slider-container .speed-display {
  font-size: 12px;
}
.slider-tools {
  height: calc(100% - 15px) !important;
}
.upload-image-icon {
  display: none;
}
.leaflet-container .leaflet-control-attribution {
  font-size: 10px;
}
</style>
