import L from 'leaflet'

L.CustomBoxZoom = L.Map.BoxZoom.extend({
  _onMouseMove: function (e) {
    if (!this._moved) {
      this._moved = true

      this._box = L.DomUtil.create('div', 'leaflet-zoom-box', this._container)
      L.DomUtil.addClass(this._container, 'leaflet-crosshair')
    }

    this._point = this._map.mouseEventToContainerPoint(e)

    var bounds = new L.Bounds(this._point, this._startPoint),
      size = bounds.getSize()
    let boxBounds = new L.LatLngBounds(this._map.containerPointToLatLng(this._startPoint), this._map.containerPointToLatLng(this._point))
    this._map.fire('boxzoomstart', { boxZoomBounds: boxBounds })

    L.DomUtil.setPosition(this._box, bounds.min)

    this._box.style.width = size.x + 'px'
    this._box.style.height = size.y + 'px'
  },
  _onMouseUp: function (e) {
    if (e.which !== 1 && e.button !== 1) {
      return
    }

    this._finish()

    if (!this._moved) {
      return
    }
    // Postpone to next JS tick so internal click event handling
    // still see it as "moved".
    this._clearDeferredResetState()
    this._resetStateTimeout = setTimeout(L.Util.bind(this._resetState, this), 0)

    var bounds = new L.LatLngBounds(this._map.containerPointToLatLng(this._startPoint), this._map.containerPointToLatLng(this._point))
    this._map.fire('boxzoomend', { boxZoomBounds: bounds })
  },
})

L.Map.addInitHook('addHandler', 'customBoxZoom', L.CustomBoxZoom)
