<template></template>

<script>
const _ = require('lodash')
import chroma from 'chroma-js'
import * as turf from '@turf/turf'
import MathMixin from '@/mixins/MathMixin.js'
import { highlightMixin } from '@/mixins/GroupItemsMixin.js'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  props: ['map', 'layer', 'mapIndex'],
  mixins: [highlightMixin, MathMixin],
  mounted() {
    // wait for map init
    this.$nextTick(async () => {
      await this.delete()
      await this.create()
      await this.update()
      // if (this.version == 1) {
      //   this.inspect();
      // }
    })
    if (this.mapIndex == 0) {
      try {
        if (this.layerSelectedOpenProject && this.layerSelectedOpenProject[`${this.layer.id}`] != undefined) {
          this.layerSelectedOpenProject[`${this.layer.id}`] = true
        }
      } catch {}
      let isCheckDrawAllLayer = true
      try {
        if (this.layerSelectedOpenProject) {
          for (let key in this.layerSelectedOpenProject) {
            if (key != 'layerSelected' && !this.layerSelectedOpenProject[key]) {
              isCheckDrawAllLayer = false
              break
            }
          }
        }
      } catch {}
      let idLayerSelected
      if (isCheckDrawAllLayer) {
        if (this.layerSelectedOpenProject && this.layerSelectedOpenProject['layerSelected']) {
          idLayerSelected = _.cloneDeep(this.layerSelectedOpenProject['layerSelected'])
          this.layerSelectedOpenProject = {}
        } else {
          if (this.layer && ['point', 'multi', 'netcdf'].includes(this.layer.type)) {
            idLayerSelected = _.cloneDeep(this.layer.id)
          }
        }
      }
      setTimeout(() => {
        try {
          if (idLayerSelected) {
            this.$store.commit('map/SET_LAYER_SELECTED', idLayerSelected)
          }
        } catch {}
      }, 500)
    }
  },
  async destroyed() {
    await this.delete()
  },
  data() {
    return {
      renderDate: '',
      operator: ['>', '>=', '<', '<=', '==', '!='],
      toastLayer: [],
      eventHasShow: [],
      getStatusSuccess: 0,
      checkTimeout: null,
      boundOld: null,
      processDataBuild: {},
      processGeoBuild: {},
      countGeoProcessRate: 1,
    }
  },
  computed: {
    processRateDataOfLayer() {
      return this.layer.processRateData
    },
    processRate() {
      try {
        return this.layer.processRate
      } catch {}
    },
    datasources() {
      return this.$store.state.datasource.datasources
    },
    version() {
      return this.layer.version
    },
    visible() {
      return this.layer.visible[this.mapIndex]
    },
    inspectCount() {
      return this.layer.inspectCount
    },
    date() {
      return this.$store.state.map.date
    },
    conditionRule() {
      return this.$store.state.map.conditionRule
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
    layers() {
      if (this.numberOfMap == 1) {
        return this.$store.state.layer.layers.filter((l) => l.visible[0] && l.type != 'image')
      } else {
        return this.$store.state.layer.layers.filter((l) => (l.visible[0] || l.visible[1]) && l.type != 'image')
      }
    },
    numberOfMap() {
      return this.$store.state.map.numberOfMap
    },
    lineColor() {
      return this.$store.state.settings.lineColor
    },
    selectedGroup() {
      return this.$store.state.map.selectedGroup
    },
    layerSelected() {
      return this.$store.state.map.layerSelected
    },
    idMultiSelected() {
      let id = 'ID'
      try {
        id = this.layer.idMultiSelected ? this.layer.idMultiSelected : 'ID'
      } catch {}
      return id
    },
    layerSelectedOpenProject: {
      get() {
        return this.$store.state.ecoplot.layerSelectedOpenProject
      },
      set(layerSelectedOpenProject) {
        this.$store.commit('ecoplot/UPDATE_LAYER_SELECTED_OPEN_PROJECT', layerSelectedOpenProject)
      },
    },
    allRecordTimeseries: {
      async get() {
        let allRecord
        try {
          if (this.layer && this.layer.dataTimeseries) {
            allRecord = await this.selectAll(this.layer.dataTimeseries, this.layer.scale.value)
          }
        } catch {}
        return allRecord
      },
    },
    processRatePolygonBuild: {
      async get() {
        let a = this.countGeoProcessRateComputed
        let b = this.countGeoProcessRate
        let dataBuild = {}
        try {
          if (this.processRate) {
            let allRecordTimeseries = _.cloneDeep(await this.allRecordTimeseries)
            let metadataColumn = this.$db[this.layer.dataTimeseries]
            let geoStep = 1
            try {
              geoStep = this.countGeoProcessRate
            } catch {}
            const recordProgessValue = allRecordTimeseries[this.layer.processRateData.progressValue]
            if (geoStep > 1) {
              if (metadataColumn && metadataColumn.dates && metadataColumn.dates.length && recordProgessValue) {
                try {
                  let fromIdx = 0
                  for (let f = 0; f < metadataColumn.dates.length; f++) {
                    let processOrigin = (Number(recordProgessValue[f]) * geoStep) / 100
                    let processOriginRound = Math.round(processOrigin)
                    // if (f == metadataColumn.dates.length - 1) {
                    //   processOriginRound = geoStep
                    // }
                    for (let m = fromIdx; m <= processOriginRound; m++) {
                      dataBuild[m] = f
                    }
                    fromIdx = processOriginRound
                  }
                } catch {}
              }
            }
          }
        } catch {}
        return dataBuild
      },
    },
    processRatePolylineBuild: {
      async get() {
        let dataBuild = {}
        let segmentStep = 0
        try {
          let processRateMethod = this.layer.processRateData.progressMethod
          if (this.processRate) {
            if (processRateMethod) {
              try {
                segmentStep = Number(this.layer.processRateData.segment)
              } catch {
                segmentStep = metadata && metadata.dates && metadata.dates.length ? metadata.dates.length : 1
              }
              let allRecordTimeseries = _.cloneDeep(await this.allRecordTimeseries)
              const metadata = this.$db[this.layer.dataTimeseries]
              const recordProgessValue = allRecordTimeseries[this.layer.processRateData.progressValue]
              if (metadata && metadata.dates && metadata.dates.length && recordProgessValue) {
                if (processRateMethod == 'distance-current') {
                  for (let ki in this.totalMultiline) {
                    try {
                      dataBuild[ki] = {}
                      let fromIdx = 0
                      for (let f = 0; f < metadata.dates.length; f++) {
                        let processOrigin = (Number(recordProgessValue[f]) * 1000) / (this.totalMultiline[ki] / segmentStep)
                        let processOriginRound = Math.round(processOrigin)
                        if (processOriginRound > segmentStep) {
                          processOriginRound = segmentStep
                        }
                        for (let m = fromIdx; m <= processOriginRound; m++) {
                          if (dataBuild[ki][m] == null) {
                            dataBuild[ki][m] = f
                          }
                        }
                        fromIdx = processOriginRound
                      }
                    } catch {}
                  }
                }
                if (processRateMethod == 'progress-current') {
                  try {
                    let fromIdx = 0
                    for (let f = 0; f < metadata.dates.length; f++) {
                      let processOrigin = (Number(recordProgessValue[f]) * segmentStep) / 100
                      let processOriginRound = Math.round(processOrigin)
                      if (processOriginRound > segmentStep) {
                        processOriginRound = segmentStep
                      }
                      for (let m = fromIdx; m <= processOriginRound; m++) {
                        if (dataBuild[m] == null) {
                          dataBuild[m] = f
                        }
                      }
                      fromIdx = processOriginRound
                    }
                  } catch {}
                }
              }
            }
          }
        } catch {}
        return dataBuild
      },
    },
    countGeoProcessRateComputed() {
      return this.countGeoProcessRate
    },
    minMaxValueItem: {
      async get() {
        let minValueItem = Infinity
        let maxValueItem = -Infinity
        try {
          if (this.layer.scale.value) {
            const metadata = this.$db[this.layer.dataTimeseries]
            let itemMinMax = this.layer.scale.value ? `${this.layer.item}_${this.layer.scale.value}` : `${this.layer.item}`
            if (metadata.minMaxItems && metadata.minMaxItems[itemMinMax] && metadata.minMaxItems[itemMinMax].min != null && metadata.minMaxItems[itemMinMax].max != null) {
              return [Number(metadata.minMaxItems[itemMinMax].min), Number(metadata.minMaxItems[itemMinMax].max)]
            }
            let locations = Object.keys(metadata.columns)
              .filter((pair) => pair.endsWith(`*${this.layer.item}`))
              .map((pair) => pair.split('*')[0])
            const locationsItems = _.map(locations, (l) => l + '-' + this.layer.item)
            let allRecord = await this.selectAllByLocationsItems(this.layer.dataTimeseries, locationsItems, this.layer.scale.value)
            for (let key in allRecord) {
              if (key != 'dates') {
                let allRecordValueTempItemsFilterNA = allRecord[key].filter((bt) => !this.isNaValue(bt))
                minValueItem = Math.min(minValueItem, _.min([].concat(...allRecordValueTempItemsFilterNA)))
                maxValueItem = Math.max(maxValueItem, _.max([].concat(...allRecordValueTempItemsFilterNA)))
              }
            }
          }
        } catch {}
        return [minValueItem, maxValueItem]
      },
    },
  },
  watch: {
    processRateDataOfLayer: {
      deep: true,
      async handler(newVal, oldVal) {
        if (`${newVal}` != `${oldVal}`) {
          await this.delete()
          await this.create()
          await this.update()
        }
      },
    },
    conditionRule() {
      this.eventHasShow = []
    },
    async version() {
      if (this.checkTimeout) clearTimeout(this.checkTimeout)
      this.checkTimeout = setTimeout(async () => {
        await this.delete()
        await this.create()
        await this.update()
      }, 100)
    },
    async countLiveMode() {
      await this.delete()
      await this.create()
      await this.update()
    },
    async processRate() {
      await this.delete()
      await this.create()
      await this.update()
    },
    async visible() {
      await this.delete()
      await this.create()
      await this.update()
      // visible highlight
      try {
        const map = (document.getElementById(`leaflet-map-${this.mapIndex}`) || {})._leaflet_map
        if (map) {
          map.eachLayer((groupLayer) => {
            if (this.selectedGroup && this.selectedGroup.layerID) {
              if (this.layer.id == this.selectedGroup.layerID && groupLayer && groupLayer.options && groupLayer.options.idLeafletLayerTemp && groupLayer.options.idLeafletLayerTemp == `${this.selectedGroup.layerID}_highlight-marker`) {
                if (!this.visible) {
                  groupLayer.options.opacity = 0
                } else {
                  groupLayer.options.opacity = 1
                }
              }
            } else {
              if (this.layer.id == this.layerSelected && groupLayer && groupLayer.options && groupLayer.options.idLeafletLayerTemp && groupLayer.options.idLeafletLayerTemp == `${this.layerSelected}_highlight-marker`) {
                if (!this.visible) {
                  groupLayer.options.opacity = 0
                } else {
                  groupLayer.options.opacity = 1
                }
              }
            }
          })
        }
      } catch {}
    },
    async date() {
      const metadata = this.$db[this.layer.dataTimeseries]
      const date = this.date[0]

      // Check is time outside of layer time-range, then it should not be draw
      const isOutside = metadata ? !(metadata.dates[0] <= date && date <= metadata.dates[metadata.dates.length - 1]) : true
      if (!this.visible && !isOutside) this.$store.commit('ecoplot/SET_STATUS_TIMEPLAYER')
      await this.update()
    },
    inspectCount() {
      this.inspect()
    },
    toastLayer(toastLayer) {
      if (toastLayer.length > 0) {
        let i = 0
        let interValFunc = setInterval(() => {
          try {
            this.$toast({ component: ToastificationContent, props: { title: ``, icon: 'BellIcon', text: toastLayer[i].text, variant: toastLayer[i].style, timeout: toastLayer[i].timeout } })
            i++
          } catch {}
          if (i >= toastLayer.length) {
            clearInterval(interValFunc)
          }
        }, 500)
      }
    },
    getStatusSuccess() {
      let status = true
      const date = _.cloneDeep(this.date[0])
      try {
        if (this.layers && this.layers.length) {
          if (this.numberOfMap == 1) {
            for (let i = 0; i < this.layers.length; i++) {
              if (!this.$layerStatus[this.layers[i].id] || !this.$layerStatus[this.layers[i].id][date] || (this.$layerStatus[this.layers[i].id] && !this.$layerStatus[this.layers[i].id][date][0])) {
                status = false
                break
              }
            }
          } else if (this.numberOfMap == 2) {
            for (let i = 0; i < this.layers.length; i++) {
              if (!this.$layerStatus[this.layers[i].id] || !this.$layerStatus[this.layers[i].id][date] || (this.$layerStatus[this.layers[i].id] && (!this.$layerStatus[this.layers[i].id][this.date[0]][0] || !this.$layerStatus[this.layers[i].id][this.date[0]][1]))) {
                status = false
                break
              }
            }
          }
        }
      } catch {}
      if (status) {
        this.$store.commit('ecoplot/SET_STATUS_FILE_EXECUTE', false)
        this.$store.commit('ecoplot/SET_STATUS_FUNC_MAP', false)
      }
    },
  },
  methods: {
    async create() {
      this.countGeoProcessRate = 1
      this.totalMultiline = {}
      const self = this
      this.processGeoBuild = {}
      this.processDataBuild = {}
      if (!this.visible) return
      if (!this.layer.dataMulti) {
        try {
          const map = (document.getElementById(`leaflet-map-${this.mapIndex}`) || {})._leaflet_map
          if (map) {
            map.eachLayer((groupLayer) => {
              if (this.layer.id == this.layerSelected && groupLayer && groupLayer.options && groupLayer.options.idLeafletLayerTemp && groupLayer.options.idLeafletLayerTemp == `${this.layerSelected}_highlight-marker`) {
                groupLayer.options.opacity = 0
              }
            })
          }
        } catch {}
        if (this.mapIndex == 0) {
          this.$store.commit('groupItems/SET_SELECTED_GROUP_ITEMS', null)
        }
        return
      }

      let leafletHightLightLayer = null
      // let markersCluster = null;
      // const markerClusterOption = {
      //   showCoverageOnHover: this.layer.markerCluster.showCoverageOnHover,
      //   zoomToBoundsOnClick: this.layer.markerCluster.zoomToBoundsOnClick,
      //   maxClusterRadius: this.layer.markerCluster.maxClusterRadius,
      //   animate: true,
      //   animateAddingMarkers: true,
      // };
      // if (!this.map.getPane(this.layer.id)) this.map.createPane(this.layer.id);
      // if (this.layer.markerCluster.using) {
      //   markersCluster = L.markerClusterGroup(markerClusterOption);
      //   leafletHightLightLayer = L.featureGroup.subGroup(markersCluster);
      // } else leafletHightLightLayer = L.featureGroup();
      leafletHightLightLayer = L.featureGroup()
      leafletHightLightLayer.addTo(this.map)
      leafletHightLightLayer.id = `hightlight_${this.layer.id}`
      leafletHightLightLayer.type = 'hightlight'

      // if (!this.map.getPane(this.layer.id)) this.map.createPane(this.layer.id);
      let geo = await this.getGeojson(this.layer.dataMulti)
      let nullIDGeo = geo.features.filter((g) => !g.properties[this.idMultiSelected])
      let validIDGeo = geo.features.filter((g) => g.properties[this.idMultiSelected])
      geo.features = nullIDGeo.concat(validIDGeo)

      let leafletLayer = L.layerGroup()
      leafletLayer.addTo(this.map)
      leafletLayer.id = this.layer.id
      leafletLayer.type = 'multi'
      leafletLayer.mapIndex = this.mapIndex

      let geoNotLine = _.cloneDeep(geo)
      let geoLine = _.cloneDeep(geo)
      if (this.processRate) {
        if (geo && geo.features.length) {
          if (this.layer.processRateData.geometryType == 'polyline') {
            let featureNotLineTemp = []
            let featureLineTemp = []
            for (let i = 0; i < geo.features.length; i++) {
              let checkLine = false
              try {
                checkLine = geo.features && geo.features.geometry && geo.features.geometry.type && (geo.features.geometry.type.toLowerCase() == 'multilinestring' || geo.features.geometry.type.toLowerCase() == 'linestring') ? true : false
              } catch {}
              try {
                if (!checkLine) {
                  checkLine = geo.features[i] && geo.features[i].geometry && geo.features[i].geometry.type && (geo.features[i].geometry.type.toLowerCase() == 'multilinestring' || geo.features[i].geometry.type.toLowerCase() == 'linestring') ? true : false
                }
              } catch {}
              if (checkLine) {
                featureLineTemp.push(geo.features[i])
              } else {
                featureNotLineTemp.push(geo.features[i])
              }
            }
            if (!featureLineTemp || !featureLineTemp.length) {
              geoLine = null
            } else {
              geoLine.features = featureLineTemp
            }
            if (!featureNotLineTemp || !featureNotLineTemp.length) {
              geoNotLine = null
            } else {
              geoNotLine.features = featureNotLineTemp
            }
          } else {
            geoNotLine = geo
            geoLine = null
          }
        }
      } else {
        geoNotLine = geo
      }
      // Progress Rate calculate Polyline
      if (this.processRate && geoLine && geoLine.features && geoLine.features.length) {
        let leafletTemp = L.geoJSON(geoLine, {
          pointToLayer: (_geoJsonPoint, latlng) => L.circleMarker(latlng),
        })

        let idxLayer = -1
        let idxPolyline = -1
        let numSegments = 1
        try {
          if (this.layer && this.layer.processRateData && this.layer.processRateData.segment) {
            numSegments = Number(this.layer.processRateData.segment)
          }
        } catch {}
        let segments = []
        let totalMultiline = {}
        leafletTemp.eachLayer((geojson) => {
          idxLayer++
          const polygon = geojson.toGeoJSON()
          let typePolygonSegment = 'polygon'
          try {
            typePolygonSegment = geojson.feature.geometry.type.toLowerCase()
          } catch {}
          switch (typePolygonSegment) {
            case 'multilinestring': {
              if (geojson.feature.geometry && geojson.feature.geometry.coordinates && geojson.feature.geometry.coordinates.length) {
                for (let m = 0; m < geojson.feature.geometry.coordinates.length; m++) {
                  let lineStringTemp = turf.lineString(geojson.feature.geometry.coordinates[m])
                  lineStringTemp.properties = _.cloneDeep(polygon.properties)
                  // Tính tổng chiều dài của LineString (đơn vị km)
                  let totalLength = turf.length(lineStringTemp, { units: 'millimeters' })
                  if (totalLength > 1) {
                    let segmentLength = (totalLength - 1) / numSegments
                    // Lưu trữ các đoạn
                    let idxSegment = 1
                    idxPolyline++
                    for (var f = 0; f < numSegments; f++) {
                      let segment = turf.lineSliceAlong(lineStringTemp, f * segmentLength, (f + 1) * segmentLength, { units: 'millimeters' })
                      if (segment.properties) {
                        segment.properties = {}
                      }
                      if (geojson.feature.properties) {
                        segment.properties = _.merge(segment.properties, geojson.feature.properties)
                      }
                      segment.idxProcessRate = idxSegment
                      segment.idxPolyline = idxPolyline
                      segment.typePolygonSegment = typePolygonSegment
                      segments.push(segment)
                      idxSegment++
                    }
                    totalMultiline[idxPolyline] = totalLength
                  }
                }
              }
              break
            }
            case 'linestring': {
              if (polygon.geometry.coordinates) {
                let lineStringTemp
                let totalLength = 0
                try {
                  lineStringTemp = turf.lineString(polygon.geometry.coordinates)
                  lineStringTemp.properties = _.cloneDeep(polygon.properties)
                  // Tính tổng chiều dài của LineString (đơn vị km)
                  totalLength = turf.length(lineStringTemp, { units: 'millimeters' })
                } catch {}

                if (totalLength > 1) {
                  let segmentLength = (totalLength - 1) / numSegments
                  // Lưu trữ các đoạn
                  let idxSegment = 1
                  idxPolyline++
                  for (var f = 0; f < numSegments; f++) {
                    let segment = turf.lineSliceAlong(lineStringTemp, f * segmentLength, (f + 1) * segmentLength, { units: 'millimeters' })
                    if (segment.properties) {
                      segment.properties = {}
                    }
                    if (geojson.feature.properties) {
                      segment.properties = _.merge(segment.properties, geojson.feature.properties)
                    }
                    segment.idxProcessRate = idxSegment
                    segment.idxPolyline = idxPolyline
                    segment.typePolygonSegment = typePolygonSegment
                    segments.push(segment)
                    idxSegment++
                  }
                  totalMultiline[idxPolyline] = totalLength
                }
              }
              break
            }
            default: {
              break
            }
          }
        })
        this.totalMultiline = totalMultiline
        if (segments && segments.length) {
          L.geoJSON(segments, {
            style: function (feature, index) {
              return {
                // color: '#' + Math.floor(Math.random() * 16777215).toString(16),
                color: '#ffffff00',
                weight: 1,
              }
            },
            onEachFeature: function (feature, layer) {
              layer.idxProcessRate = feature.idxProcessRate
              layer.idxPolyline = feature.idxPolyline
              layer.idxGeo = idxLayer
              layer.typeGeoPcr = feature.typePolygonSegment
              try {
                let centroid = turf.centroid(feature)
                let latlngLayer = turf.getCoord(centroid).reverse()
                layer.latlng = latlngLayer
                layer.noneId = `${self.layer.id}-${latlngLayer}`
              } catch {}
              layer.on('click', (ev) => {
                L.DomEvent.stopPropagation(ev)
                L.DomEvent.preventDefault(ev)
                let layerId = self.layer.id
                let storeData = {
                  data: { layerId, source: 'map', clickId: feature.idxProcessRate, step: feature.idxProcessRate, latlng: layer.latlng, multiShow: false, pin: true },
                  mapIndex: self.mapIndex,
                }
                self.$store.commit('map/SET_CLICKING_PROCESS_RATE', storeData)
              })
              layer.on('mouseover', () => {
                let storeData = {
                  data: { layerId: self.layer.id, hoverId: feature.idxProcessRate, step: feature.idxProcessRate, latlng: layer.latlng, source: 'map' },
                  mapIndex: self.mapIndex,
                }
                self.$store.commit('map/SET_HOVERING_PROCESS_RATE', storeData)
              })
              layer.on('mouseout', () => {
                const storeData = {
                  data: null,
                  mapIndex: self.mapIndex,
                }
                self.$store.commit('map/SET_HOVERING_PROCESS_RATE', storeData)
              })
              leafletLayer.addLayer(layer)
            },
          })
        }
      }
      if (this.processRate) {
        try {
          if (this.layer.processRateData.geometryType == 'polygon') {
            geoNotLine.features = _.sortBy(geoNotLine.features, [`properties.${self.idMultiSelected}`])
          }
        } catch {}
      }
      if (geoNotLine && geoNotLine.features && geoNotLine.features.length) {
        this.countGeoProcessRate = geoNotLine.features.length
        const geoNotLineLeafletLayer = L.geoJSON(geoNotLine, {
          pointToLayer: (_geoJsonPoint, latlng) => L.circleMarker(latlng),
          // pane: this.layer.id,
        })
        let arrIdGeojson = []
        let keyIdDataMulti
        let keyOriginMulti
        try {
          keyIdDataMulti = this.selectedGroupItems.keyId
          keyOriginMulti = this.selectedGroupItems.keyOrigin
        } catch {}
        let indexGeoProcessRate = -1
        geoNotLineLeafletLayer.eachLayer((geojson) => {
          indexGeoProcessRate++
          arrIdGeojson.push(geojson.feature.properties[this.idMultiSelected])
          let centroid = turf.centroid(geojson.toGeoJSON())
          let latlng = turf.getCoord(centroid).reverse()
          geojson.latlng = latlng
          geojson.noneId = `${this.layer.id}-${geojson.latlng}`
          if (this.processRate) {
            geojson.idxProcessRate = indexGeoProcessRate
          }
          // geojson.feature.properties.UID = undefined;
          this.$latlngOnMap[geojson.feature.properties[this.idMultiSelected]] = latlng
          geojson.on('mouseover', () => {
            // if (!geojson.feature.properties[this.idMultiSelected]) return;
            let storeData = {
              data: { layerId: this.layer.id, hoverId: geojson.feature.properties[this.idMultiSelected], hoverUid: geojson.feature.properties.UID, source: 'map' },
              mapIndex: this.mapIndex,
            }
            try {
              if (!this.layer.dataTimeseries) {
                if (geojson.feature.properties) {
                  if (this.layer.idMultiSelected) {
                    storeData.data.idMultiSelected = geojson.feature.properties[this.layer.idMultiSelected]
                  } else {
                    storeData.data.idMultiSelected = geojson.feature.properties[Object.keys(geojson.feature.properties)[0]]
                  }
                }
                storeData.data.latlngMultiSelected = geojson.latlng
              }
            } catch {}
            this.$store.commit('map/SET_HOVERING', storeData)
          })
          geojson.on('mouseout', () => {
            const storeData = {
              data: null,
              mapIndex: this.mapIndex,
            }
            this.$store.commit('map/SET_HOVERING', storeData)
          })
          geojson.on('click', (ev) => {
            L.DomEvent.stopPropagation(ev)
            if (ev.originalEvent && ev.originalEvent.ctrlKey) {
              this.toggleHighlight(this.layer, ev)
            } else {
              let clickUid
              try {
                clickUid = leafletLayer._layers[Object.keys(leafletLayer._layers)[arrIdGeojson.indexOf(geojson.feature.properties[this.idMultiSelected])]].feature.properties.UID
              } catch {
                clickUid = geojson.feature.properties.UID
              }
              let layerId = this.layer.id,
                clickId = geojson.feature.properties[this.idMultiSelected] || `${geojson.noneId}`,
                latlng = geojson.latlng
              let storeData = {
                data: { layerId, clickId, clickUid, source: 'map', multiShow: this.layer.popup.multiShow, latlng, pin: true },
                mapIndex: this.mapIndex,
              }
              try {
                if (!this.layer.dataTimeseries && this.layer.idMultiSelected && geojson.feature.properties[this.layer.idMultiSelected]) {
                  storeData.data.itemMultiSelected = geojson.feature.properties[this.layer.idMultiSelected]
                }
              } catch {}
              try {
                if (!this.layer.popup.multiShow) {
                  let clickingSingle = _.cloneDeep(this.$store.state.map.clicking[this.mapIndex])
                  if (clickingSingle && clickingSingle.length == 1 && clickingSingle[0].position && !clickingSingle[0].pin && clickingSingle[0].layerId == this.layer.id) {
                    storeData.data.pin = clickingSingle[0].pin
                    storeData.data.position = clickingSingle[0].position
                  }
                }
              } catch {}
              this.$store.commit('map/SET_CLICKING', storeData)
            }
          })
          let checkGeoJsonPoint = false
          try {
            if (geojson.feature.geometry.coordinates.length == 2 && !_.isArray(geojson.feature.geometry.coordinates[0])) {
              checkGeoJsonPoint = true
            }
          } catch {}
          if (checkGeoJsonPoint) {
            let keyIdsValue = `${geojson.feature.properties[keyOriginMulti]}___${keyIdDataMulti}___${geojson.feature.properties[keyIdDataMulti]}`
            // let keyIdsValue = keyIdDataMulti && keyIdDataMulti == 'ID' ? geojson.feature.properties['ID'] : `${geojson.feature.properties[Object.keys(geojson.feature.properties)[0]]}___${keyIdDataMulti}___${geojson.feature.properties[keyIdDataMulti]}`;
            let isCheck = false
            try {
              if (this.layer.id == this.selectedGroupItems.layerID) {
                isCheck = keyIdDataMulti && keyIdDataMulti == 'ID' && keyIdsValue ? this.selectedGroupItems.items.indexOf(keyIdsValue) > -1 : this.selectedGroupItems.itemsId.indexOf(keyIdsValue) > -1
              }
            } catch {}
            if (isCheck) {
              let circleMarker = new L.HightLightPoint([geojson.latlng[0], geojson.latlng[1]])
              circleMarker.showHighlight = true
              circleMarker.feature = { properties: { ID: `${[geojson.latlng[0], geojson.latlng[1]]}` } }
              circleMarker.highlightColor = this.lineColor
              circleMarker._radius = geojson._radius || 5
              circleMarker.fillOpacity = 0
              circleMarker.opacity = 0
              circleMarker.weight = 0
              circleMarker.color = 'transparent'
              circleMarker.fillColor = 'transparent' // Đặt màu fill
              circleMarker.layerID = 'highlight-marker'
              circleMarker.addTo(leafletHightLightLayer)
              geojson.idLayerHighlightPoint = circleMarker._leaflet_id
              circleMarker.on('mouseover', () => {
                // if (!geojson.feature.properties[this.idMultiSelected]) return;
                let storeData = {
                  data: { layerId: this.layer.id, hoverId: geojson.feature.properties[this.idMultiSelected], hoverUid: geojson.feature.properties.UID, source: 'map' },
                  mapIndex: this.mapIndex,
                }
                try {
                  if (!this.layer.dataTimeseries) {
                    if (geojson.feature.properties) {
                      if (this.layer.idMultiSelected) {
                        storeData.data.idMultiSelected = geojson.feature.properties[this.layer.idMultiSelected]
                      } else {
                        storeData.data.idMultiSelected = geojson.feature.properties[Object.keys(geojson.feature.properties)[0]]
                      }
                    }
                    storeData.data.latlngMultiSelected = geojson.latlng
                  }
                } catch {}
                this.$store.commit('map/SET_HOVERING', storeData)
              })
              circleMarker.on('mouseout', () => {
                const storeData = {
                  data: null,
                  mapIndex: this.mapIndex,
                }
                this.$store.commit('map/SET_HOVERING', storeData)
              })
              circleMarker.on('click', (ev) => {
                L.DomEvent.stopPropagation(ev)
                if (ev.originalEvent && ev.originalEvent.ctrlKey) {
                  this.toggleHighlight(this.layer, ev)
                } else {
                  let clickUid
                  try {
                    clickUid = leafletLayer._layers[Object.keys(leafletLayer._layers)[arrIdGeojson.indexOf(geojson.feature.properties[this.idMultiSelected])]].feature.properties.UID
                  } catch {
                    clickUid = geojson.feature.properties.UID
                  }
                  let layerId = this.layer.id,
                    clickId = geojson.feature.properties[this.idMultiSelected],
                    latlng = geojson.latlng
                  let storeData = {
                    data: { layerId, clickId, clickUid, source: 'map', multiShow: this.layer.popup.multiShow, latlng, pin: true },
                    mapIndex: this.mapIndex,
                  }
                  try {
                    if (!this.layer.dataTimeseries && this.layer.idMultiSelected && geojson.feature.properties[this.layer.idMultiSelected]) {
                      storeData.data.itemMultiSelected = geojson.feature.properties[this.layer.idMultiSelected]
                    }
                  } catch {}
                  try {
                    if (!this.layer.popup.multiShow) {
                      let clickingSingle = _.cloneDeep(this.$store.state.map.clicking[this.mapIndex])
                      if (clickingSingle && clickingSingle.length == 1 && clickingSingle[0].position && !clickingSingle[0].pin && clickingSingle[0].layerId == this.layer.id) {
                        storeData.data.pin = clickingSingle[0].pin
                        storeData.data.position = clickingSingle[0].position
                      }
                    }
                  } catch {}
                  this.$store.commit('map/SET_CLICKING', storeData)
                }
              })
            }
          }
          geojson.mapIndex = this.mapIndex
          geojson.checkGeoJsonPoint = checkGeoJsonPoint
          geojson.typeGeoPcr = 'polygon'
          leafletLayer.addLayer(geojson)
        })
      }
      this.leafletLayer = leafletLayer
      this.leafletHightLightLayer = leafletHightLightLayer
      this.$emit('emitBringToBackLayerStore')
    },
    async update() {
      const self = this
      const date = this.date[0]
      if (!this.$layerStatus[this.layer.id]) this.$layerStatus[this.layer.id] = {}
      if (!this.$layerStatus[this.layer.id][date]) this.$layerStatus[this.layer.id][date] = [false, false]
      if (!this.leafletLayer) {
        this.$layerStatus[this.layer.id][date][this.mapIndex] = true
        this.getStatusSuccess++
        return
      }
      const metadata = this.$db[this.layer.dataTimeseries]
      // Check is time outside of layer time-range, then it should not be draw
      const isOutside = metadata ? !(metadata.dates[0] <= date && date <= metadata.dates[metadata.dates.length - 1]) : true
      // if (!this.visible && !isOutside) this.$store.commit('ecoplot/SET_STATUS_TIMEPLAYER');
      if (!this.visible) {
        this.$layerStatus[this.layer.id][date][this.mapIndex] = true
        this.getStatusSuccess++
        return
      }
      this.$layerStatus[this.layer.id][date][this.mapIndex] = false
      this.$store.commit('ecoplot/SET_STATUS_FUNC_MAP', true)

      // Get the last record having date smaller than current date

      let record = null
      if (!isOutside && this.layer.dataTimeseries && this.layer.item) {
        record = await this.selectDateByItem(this.layer.dataTimeseries, this.layer.item, date, this.layer.scale.value)
        this.renderDate = date
      }
      let arrRuleShowPopup = []
      this.NAValRecord = []
      let allRecord
      try {
        allRecord = await this.allRecordTimeseries
      } catch {
        let locations = Object.keys(metadata.columns)
          .filter((pair) => pair.endsWith(`*${this.layer.item}`))
          .map((pair) => pair.split('*')[0])
        const locationsItems = _.map(locations, (l) => l + '-' + this.layer.item)
        allRecord = await this.selectAllByLocationsItems(this.layer.dataTimeseries, locationsItems, this.layer.scale.value)
      } finally {
      }
      let minValueItem = Infinity
      let maxValueItem = -Infinity
      try {
        let minmaxComputed = await this.minMaxValueItem
        minValueItem = minmaxComputed[0]
        minValueItem = minmaxComputed[1]
      } catch {}
      let idxDate = -1
      let processRatePolylineBuild
      if (this.processRate) {
        processRatePolylineBuild = await this.processRatePolylineBuild
      }
      let processRatePolygonBuild
      if (this.processRate) {
        try {
          processRatePolygonBuild = await this.processRatePolygonBuild
        } catch {}
      }
      try {
        if (this.conditionRule && this.conditionRule.length > 0) {
          this.conditionRule.map((data, index) => {
            if (data.layer == this.layer.id) {
              arrRuleShowPopup.push(index)
            }
          })
        }
      } catch {}
      this.toastLayer = []
      let unit = ''
      try {
        unit = this.$db[this.layer.dataTimeseries].items[this.layer.item].unit
      } catch {}
      try {
        if (metadata.dates && metadata.dates.length) {
          idxDate = metadata.dates.indexOf(date)
        }
      } catch {}
      this.leafletLayer.eachLayer((geojson) => {
        const locationItem = geojson.feature.properties[this.idMultiSelected] + '-' + this.layer.item
        let value
        let radius = 0
        let typeGeoPcr = geojson.typeGeoPcr
        if (this.processRate && typeGeoPcr && (typeGeoPcr.toLowerCase() == 'multilinestring' || typeGeoPcr.toLowerCase() == 'linestring')) {
          let idxProcessRate = geojson.idxProcessRate != null ? geojson.idxProcessRate : -2
          let processRateMethod
          let idxDateOfGeojson = -1
          try {
            geojson.bringToBack()
          } catch {}
          try {
            processRateMethod = this.layer.processRateData.progressMethod
            if (processRateMethod) {
              if (processRateMethod == 'progress-current') {
                idxDateOfGeojson = processRatePolylineBuild[idxProcessRate]
              }
              if (processRateMethod == 'distance-current') {
                let idxPolylineGeo = geojson.idxPolyline != null ? geojson.idxPolyline : -2
                idxDateOfGeojson = processRatePolylineBuild[idxPolylineGeo][idxProcessRate]
              }
            }
          } catch {}
          if (idxDateOfGeojson >= 0 && idxDate >= 0 && idxDate >= idxDateOfGeojson) {
            try {
              value = allRecord[locationItem][idxDateOfGeojson]
            } catch {}
          }
          if (value == null) {
            if (!this.layer.navalue.visible) {
              geojson.setStyle({ stroke: true, fill: false, weight: 0, color: '#ffffff00' })
            } else {
              const weight = +this.layer.navalue.weight
              const color = this.layer.navalue.color
              const fillColor = this.layer.navalue.fillColor
              const fillOpacity = +this.layer.navalue.fillOpacity
              geojson.setStyle({ stroke: true, fill: false, weight, color, fillColor, fillOpacity })
            }
          } else {
            let valueMin = 0
            let valueMax = 50
            try {
              let itemMultiSelectedLayer = this.$db[this.layer.dataMulti].items[this.layer.itemMultiSelected]
              valueMin = this.layer.scale.value ? minValueItem : metadata && metadata.items && this.layer.item && metadata.items[this.layer.item] ? metadata.items[this.layer.item].min : this.isNumericString(itemMultiSelectedLayer.min) || (_.isString(itemMultiSelectedLayer.min) && !isNaN(Number(itemMultiSelectedLayer.min))) ? Number(itemMultiSelectedLayer.min) : 0
              valueMax = this.layer.scale.value ? maxValueItem : metadata && metadata.items && this.layer.item && metadata.items[this.layer.item] ? metadata.items[this.layer.item].max : this.isNumericString(itemMultiSelectedLayer.max) || (_.isString(itemMultiSelectedLayer.max) && !isNaN(Number(itemMultiSelectedLayer.max))) ? Number(itemMultiSelectedLayer.max) : 50
            } catch {}
            // const radiusMin = +this.layer.radius.min;
            // const radiusMax = +this.layer.radius.max;
            // radius = ((value - valueMin) / (valueMax - valueMin)) * (radiusMax - radiusMin) + radiusMin;
            const weight = +this.layer.weight
            // const color = this.layer.color;
            // const fillOpacity = +this.layer.fillOpacity;
            // geojson._radius = radius;
            let fillColor = null
            if (this.layer.fillColor.mode === 'solid') {
              fillColor = this.layer.fillColor.color
            } else if (this.layer.fillColor.mode === 'linear') {
              fillColor = chroma.scale(this.layer.fillColor.colors).domain([valueMin, valueMax])(value)
            } else if (this.layer.fillColor.mode === 'threshold') {
              fillColor = this.layer.fillColor.color
              let values = this.layer.fillColor.thresholds[this.layer.fillColor.individualThreshold ? geojson.feature.properties[this.idMultiSelected] : 'General']
              for (let index = 0; index < this.layer.fillColor.levels.length; index++) {
                if (values[index] !== '' && value >= +values[index]) fillColor = this.layer.fillColor.levels[index].color
              }
            }
            geojson.setStyle({ stroke: true, fill: false, weight, color: fillColor })
          }
          // } else {
          //   geojson.setStyle({ stroke: true, fill: false, weight: 0, color: '#ffffff00' })
          // }
        } else {
          let checkPolygonLine = false
          try {
            checkPolygonLine = geojson.feature && geojson.feature.geometry && geojson.feature.geometry.type && (geojson.feature.geometry.type.toLowerCase() == 'multilinestring' || geojson.feature.geometry.type.toLowerCase() == 'linestring') ? true : false
          } catch {}
          if (this.processRate) {
            if (!checkPolygonLine && this.layer && this.layer.processRateData && this.layer.processRateData.geometryType && this.layer.processRateData.geometryType == 'polygon') {
              let idxProcessRate = geojson.idxProcessRate != null ? geojson.idxProcessRate : -2
              if (idxProcessRate >= 0 && processRatePolygonBuild && processRatePolygonBuild[idxProcessRate] >= 0 && idxDate >= 0 && idxDate >= processRatePolygonBuild[idxProcessRate]) {
                try {
                  value = allRecord[this.layer.processRateData.polygonValue][processRatePolygonBuild[idxProcessRate]]
                } catch {}
              }
            }
          } else {
            if (record) {
              value = record[locationItem]
            } else if (this.layer.itemMultiSelected && !this.layer.dataTimeseries) {
              try {
                let valueTemp = geojson.feature.properties[this.layer.itemMultiSelected]
                if (valueTemp && (this.isNumericString(valueTemp) || (_.isString(valueTemp) && !isNaN(Number(valueTemp))))) {
                  value = Number(valueTemp)
                }
              } catch {}
            }
          }
          if (value == null) {
            if (!this.layer.navalue.visible) {
              geojson.setStyle({ stroke: false, fill: false })
            } else {
              radius = +this.layer.navalue.radius
              const weight = +this.layer.navalue.weight
              const color = this.layer.navalue.color
              const fillColor = this.layer.navalue.fillColor
              const fillOpacity = +this.layer.navalue.fillOpacity
              geojson._radius = radius
              if (checkPolygonLine) {
                if (geojson.setRadius) geojson.setRadius(radius)
                geojson.setStyle({ stroke: true, fill: false, weight, color: fillColor })
              } else {
                if (geojson.setRadius) geojson.setRadius(radius)
                geojson.setStyle({ stroke: true, fill: true, weight, color, fillColor, fillOpacity })
              }
            }
            this.NAValRecord.push(locationItem)
          } else {
            let valueMin = 0
            let valueMax = 50
            try {
              let itemMultiSelectedLayer = this.$db[this.layer.dataMulti].items[this.layer.itemMultiSelected]
              valueMin = this.layer.scale.value ? minValueItem : metadata && metadata.items && this.layer.item && metadata.items[this.layer.item] ? metadata.items[this.layer.item].min : this.isNumericString(itemMultiSelectedLayer.min) || (_.isString(itemMultiSelectedLayer.min) && !isNaN(Number(itemMultiSelectedLayer.min))) ? Number(itemMultiSelectedLayer.min) : 0
              valueMax = this.layer.scale.value ? maxValueItem : metadata && metadata.items && this.layer.item && metadata.items[this.layer.item] ? metadata.items[this.layer.item].max : this.isNumericString(itemMultiSelectedLayer.max) || (_.isString(itemMultiSelectedLayer.max) && !isNaN(Number(itemMultiSelectedLayer.max))) ? Number(itemMultiSelectedLayer.max) : 50
            } catch {}
            const radiusMin = +this.layer.radius.min
            const radiusMax = +this.layer.radius.max
            radius = ((value - valueMin) / (valueMax - valueMin)) * (radiusMax - radiusMin) + radiusMin
            const weight = +this.layer.weight
            const color = this.layer.color
            const fillOpacity = +this.layer.fillOpacity
            geojson._radius = radius
            let fillColor = null
            if (this.layer.fillColor.mode === 'solid') {
              fillColor = this.layer.fillColor.color
            } else if (this.layer.fillColor.mode === 'linear') {
              fillColor = chroma.scale(this.layer.fillColor.colors).domain([valueMin, valueMax])(value)
            } else if (this.layer.fillColor.mode === 'threshold') {
              fillColor = this.layer.fillColor.color
              let values = this.layer.fillColor.thresholds[this.layer.fillColor.individualThreshold ? geojson.feature.properties[this.idMultiSelected] : 'General']
              for (let index = 0; index < this.layer.fillColor.levels.length; index++) {
                if (values[index] !== '' && value >= +values[index]) fillColor = this.layer.fillColor.levels[index].color
              }
            }
            if (checkPolygonLine) {
              geojson.setStyle({ stroke: true, fill: false, weight, color: fillColor })
            } else {
              if (geojson.setRadius) geojson.setRadius(radius)
              geojson.setStyle({ stroke: true, fill: true, weight, color, fillColor, fillOpacity })
            }
            this.addAutoPopup(arrRuleShowPopup, value, geojson.latlng, this.layer, locationItem, date, unit)
          }
          if (geojson.checkGeoJsonPoint && geojson.idLayerHighlightPoint && this.leafletHightLightLayer._layers[geojson.idLayerHighlightPoint]) {
            let radius = 2 * geojson._radius || 5
            this.leafletHightLightLayer._layers[geojson.idLayerHighlightPoint].setRadius(radius)
          }
        }
      })

      this.$layerStatus[this.layer.id][date][this.mapIndex] = true
      this.getStatusSuccess++
    },
    delete() {
      try {
        if (this.leafletLayer && this.map) {
          this.map.removeLayer(this.leafletLayer)
          this.leafletLayer.off()
          this.leafletLayer.remove()
        }
      } catch {
        if (this.leafletLayer) {
          this.leafletLayer.eachLayer((geojson) => {
            geojson.off()
            geojson.remove()
          })
          this.leafletLayer.off()
          this.leafletLayer.remove()
        }
      } finally {
      }
      try {
        if (this.leafletHightLightLayer && this.map) {
          this.map.removeLayer(this.leafletHightLightLayer)
          this.leafletHightLightLayer.off()
          this.leafletHightLightLayer.remove()
        }
      } catch {
        if (this.leafletHightLightLayer) {
          this.leafletHightLightLayer.eachLayer((highLightPoint) => {
            highLightPoint.off()
            highLightPoint.remove()
          })
          this.leafletHightLightLayer.off()
          this.leafletHightLightLayer.remove()
        }
      } finally {
      }
    },
    async inspect() {
      try {
        let zoomNow = 20
        let zoomMax = 20
        if (!this.leafletLayer && !this.layer.dataMulti) return
        const layers = this.leafletLayer._layers
        let bound
        try {
          bound = this.leafletLayer.getBounds()
        } catch {}
        let bounds
        if (this.selectedGroupItems && this.selectedGroupItems.items) {
          try {
            let overallBounds1 = L.latLngBounds()
            let count = 0
            for (let i in layers) {
              const locationItem = `${layers[i].feature.properties[this.idMultiSelected]}-${this.layer.item}`
              if (layers[i].feature.properties[this.idMultiSelected] != null && this.selectedGroupItems.items.indexOf(layers[i].feature.properties[this.idMultiSelected]) > -1 && this.NAValRecord.indexOf(locationItem) < 0) {
                try {
                  let boundTemp = layers[i].getBounds()
                  overallBounds1.extend(boundTemp)
                  count++
                } catch {}
              }
            }
            if (count > 0) {
              bounds = overallBounds1
            }
          } catch {}
        } else {
          if (this.NAValRecord && this.NAValRecord.length) {
            try {
              let overallBounds2 = L.latLngBounds()
              let count = 0
              for (let i in layers) {
                const locationItem = `${layers[i].feature.properties[this.idMultiSelected]}-${this.layer.item}`
                if (this.NAValRecord.indexOf(locationItem) < 0) {
                  try {
                    let boundTemp = layers[i].getBounds()
                    overallBounds2.extend(boundTemp)
                    count++
                  } catch {}
                }
              }
              if (count > 0) {
                bounds = overallBounds2
              }
            } catch {}
          }
          if (!bounds) {
            try {
              let overallBounds3 = L.latLngBounds()
              for (let i in layers) {
                try {
                  let boundTemp = layers[i].getBounds()
                  overallBounds3.extend(boundTemp)
                } catch {}
              }
              bound = overallBounds3
            } catch {}
          }
        }
        if (this.map) {
          if (bounds) {
            try {
              let zoomFullAll = this.map.getBoundsZoom(bounds)
              zoomNow = zoomFullAll >= zoomMax ? zoomMax : zoomFullAll
            } catch {}
            this.map.fitBounds(bounds, {
              animate: true,
              duration: 0.4,
              maxZoom: zoomNow,
            })
          } else if (bound) {
            try {
              let zoomFullAll = this.map.getBoundsZoom(bound)
              zoomNow = zoomFullAll >= zoomMax ? zoomMax : zoomFullAll
            } catch {}
            this.map.fitBounds(bound, {
              animate: true,
              duration: 0.4,
              maxZoom: zoomNow,
            })
          }
        }
      } catch {}
    },
    addAutoPopup(arrRuleShowPopup, value, LatLng, layer, locationItem, date, unit) {
      try {
        let htmlPopup = ''
        let popupMarker = []
        if (arrRuleShowPopup.length > 0) {
          for (let k = 0; k < arrRuleShowPopup.length; k++) {
            let rule = this.conditionRule[arrRuleShowPopup[k]]
            let operatorRule = rule.condition
            let valueRule = Number(rule.value)
            let message = this.tAll(rule.message, { LOCATION: `${locationItem}`, ITEM: `${layer.item}`, TIME: `${date}`, LAYER: `${layer.name}`, CONDITION: `${operatorRule}`, VALUE: value, UNIT: `${unit}` })
            if ((operatorRule === `${this.operator[0]}` && value > valueRule) || (operatorRule === `${this.operator[1]}` && value >= valueRule) || (operatorRule === `${this.operator[2]}` && value < valueRule) || (operatorRule === `${this.operator[3]}` && value <= valueRule) || (operatorRule === `${this.operator[4]}` && value == valueRule) || (operatorRule === `${this.operator[5]}` && value != valueRule)) {
              let duration = Number(rule.duration)
              if (!rule.multiShowEvent) {
                let checkShow = this.eventHasShow.filter((e) => e.locationItem === locationItem && e.layerID === layer.id)
                if (checkShow && checkShow.length === 0) this.eventHasShow.push({ locationItem: locationItem, layerID: layer.id })
                else continue
              }
              if (rule.visible == 'popup') {
                htmlPopup = `
                      <div class='popup-auto-event svgText animated fadeOutUp event-color-text-${rule.style}' style='-webkit-animation-duration: ${duration / 1000}s;animation-duration: ${duration / 1000}s;'>
                        <div>${message}</div>
                      </div>
                      `
                popupMarker[`${k}_${rule.layer}`] = L.popup({ autoPan: false }).setLatLng(LatLng).setContent(htmlPopup).addTo(this.map)
                setTimeout(() => {
                  popupMarker[`${k}_${rule.layer}`].removeFrom(this.map)
                }, duration)
              } else {
                this.toastLayer.push({ style: rule.style, text: message, timeout: duration })
              }
            }
          }
        }
      } catch {}
    },
    tAll(string, param = {}) {
      try {
        let stringTemp = string
        let keyParam = Object.keys(param)
        if (keyParam.length > 0) {
          for (let i = 0; i < keyParam.length; i++) {
            string = string.replaceAll(`[${keyParam[i]}]`, `${param[keyParam[i]]}`)
          }
        }
        return string
      } catch {
        return stringTemp
      }
    },
    isNumericString(str) {
      try {
        const num = _.toNumber(str)
        return !_.isNaN(num) && _.isFinite(num)
      } catch {}
      return false
    },
  },
}
</script>
<style lang="scss">
.marker-cluster {
  color: #000;
}

.leaflet-popup.leaflet-zoom-animated {
  margin-bottom: 0;
  pointer-events: none;

  .leaflet-popup-content {
    box-shadow: none;
    background: transparent;
  }

  .leaflet-popup-close-button {
    display: none;
  }

  .leaflet-popup-content-wrapper {
    box-shadow: none;
    background: transparent;
  }

  .leaflet-popup-tip-container {
    display: none;
  }
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.popup-auto-event {
  // max-width: 200px;
  pointer-events: none;
  font-weight: bold;
  font-size: 14px;
  text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff, 1px 0 0 #fff, 1px 1px 0 #fff, 0 1px 0 #fff, -1px 1px 0 #fff, -1px 0 0 #fff;
}

.svgText {
  fill: white;
  stroke: black;
  stroke-width: 15px;
  stroke-linejoin: round;
  paint-order: stroke;
}
</style>
